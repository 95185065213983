<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('common.import_excel') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="12">

          <wameed-input-file-upload

              rule="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              fileType="specific"
              field-classes="w-100"
              functionType="front"
              v-model="form.excel.name"
              :label="$t('common.upload_file')"
              :input-placeholder="$t('form.excel.placeholder')"
              :input-append-text="$t('form.excel.browse')"
              @changed="uploadFile"
          />
        </b-col>
      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.add')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    modalData: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      form: {
        excel: '',
      },
    };
  },
  computed: {

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {

    uploadFile(value) {
      this.form.excel = value;
    },

    submitOrder() {
      this.$emit('onSubmit', this.form.excel)
    },


    closeModal() {
      this.form = {
        excel: '',
      };
      this.show = false;
    },
  },
};
</script>
